<template>
  <div>Hello, يمكنك تجربة شاي الكابوس مع النعناع والقرنفل. مع مراعاة عدم تتقيل الشاي لانه بيبقا مر.</div>
</template>

<script>
export default {

}
</script>

<style>

</style>